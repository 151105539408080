.stake-main{
    @apply flex flex-col justify-center items-center min-h-full;
    padding: 20px 0;
}
.stake-box{
    @apply rounded-lg px-4 md:px-6 py-6 md:py-7 mx-auto;
    width: 100%;
    max-width: 570px;
    background: var(--bg-dark-600);
    border: 1px solid rgb(255 255 255 / 7%);
}
.stake-box .header{
    @apply flex justify-between mb-7;
}
.stake-box .header .stacking-tabs{
    @apply flex items-center gap-x-6;
}
.stake-box .header .stacking-tabs .tab-item{
    @apply text-lg md:text-2xl leading-none md:leading-none m-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.stake-box .header .stacking-tabs .tab-item.active{
    color: var(--text-white-100);
}
.stake-box .header .btn-settings{
    @apply px-1 py-1;
}
.stake-box .body .enter-balance{
    @apply relative mb-3.5;
}
.stake-box .body .enter-balance .icon{
    @apply absolute top-1/2 left-4;
    transform: translateY(-50%);
}
.stake-box .body .enter-balance .field{
    @apply w-full text-sm md:text-base rounded-2xl shadow-none outline-0;
    height: 55px;
    padding: 0 65px 0 60px;
    font-weight: var(--fw-400);
    color: var(--text-white-200);
    background: var(--bg-dark-500);
    border: 1px solid rgba(255, 255, 255, 0.04);
}
.stake-box .body .enter-balance .field:focus{
    border: 1px solid rgba(255, 255, 255, 0.04) !important;
    outline: 1px solid rgba(255, 255, 255, 0.04) !important;
}

.stake-box .body .enter-balance .field::placeholder{
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .field::-webkit-input-placeholder {
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .field::-moz-placeholder {
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .field:-ms-input-placeholder {
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .field:-moz-placeholder {
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .tag{
    @apply absolute top-1/2 right-4 w-11 h-6 flex items-center justify-center text-xs rounded-lg;
    transform: translateY(-50%);
    font-weight: var(--fw-700);
    color: var(--text-dark-300);
    background-color: var(--bg-dark-800);
}
.stake-box .body .velar-balance{
    @apply text-base leading-none text-right mb-6;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
.stake-box .body .will-recieve{
    @apply w-full flex items-center justify-between gap-x-4 px-4 py-3.5;
    background: var(--bg-warning-400);
    border: 1px solid rgba(248, 157, 51, 0.06);
    border-radius: 10px;
}
.stake-box .body .will-recieve .text{
    @apply text-sm md:text-base leading-none md:leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-warning-300);
}
.stake-box .body .will-recieve .value{
    @apply text-base md:text-lg leading-none md:leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-warning-300);
}

.stake-box .body .staking-details{
    @apply w-full p-3;
    background-color: rgba(244, 244, 245, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 10px;
}
.stake-box .body .staking-details button{
    @apply flex items-center justify-between gap-x-5 text-base;
    font-weight: var(--fw-600);
    color: var(--text-white-700);
}
.stake-box .body .staking-details #staking-details{
    @apply pt-5;
}
.stake-box .body .staking-details #staking-details .key{
    @apply text-sm capitalize leading-none mb-0;
    font-weight: var(--fw-400);
}
.stake-box .body .staking-details #staking-details .value{
    @apply text-sm text-right leading-none mb-0;
    font-weight: var(--fw-400);
}
.stake-box .body .staking-details #staking-details hr{
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.my-rewards .block-info {
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.02);
    padding: 5px 8px 8px 8px;
    border-radius: 8px;
}

.my-rewards .block-info .block-btn {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.my-rewards .block-info .block-btn .title {
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.my-rewards .block-info .details {
    font-size: 13px;
    margin: 0px 6px 0px 6px;
}


.my-rewards .block-info .value {
    color: rgba(113, 113, 121, 1);
}


.my-rewards .block-info .block-btn .dotted-line {
    width: 66%;
    height: 2px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFFFFF14' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

@media screen and (max-width: 767.98px) {
    .my-rewards .block-info .block-btn .dotted-line {
        width: 62%;
    }
}

@media screen and (max-width: 550.98px) {
    .my-rewards .block-info .block-btn .dotted-line {
        width: 50%;
    }
}

@media screen and (max-width: 400.98px) {
    .my-rewards .block-info .block-btn .dotted-line {
        width: 45%;
    }
}