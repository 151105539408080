.faucet-main {
  @apply flex justify-center min-h-full;
  padding: 20px 0;
  margin-top: 35px;
}
.faucet-main .faucet-box {
  @apply rounded-lg px-3.5 md:px-6 py-4 md:py-7;
  width: 100%;
  max-width: 580px;
  background: var(--bg-dark-600);
  border: 1px solid rgb(255 255 255 / 7%);
}
.faucet-main .faucet-box .header {
  @apply flex items-center justify-between mb-4 md:mb-6;
}
.faucet-main .faucet-box .header .heading {
  @apply leading-none;
  font-size: 24px;
  font-weight: var(--fw-400);
  color: var(--text-white-100);
}

.faucet-main .faucet-box .header .btn-settings {
  @apply px-1 py-1;
}
.faucet-main .faucet-box .header .btn-settings img {
  @apply w-4 md:w-6;
}

.faucet-main .faucet-box .faucet-container {
  @apply relative mb-2.5 md:mb-5;
}

.faucet-main .faucet-box .faucet-container .faucet-from .badge {
  @apply rounded-lg text-xs w-8 md:w-11 h-4 md:h-6 flex items-center justify-center mb-0;
  font-weight: var(--fw-700);
  color: var(--text-dark-300);
  background: var(--bg-dark-800);
}
.faucet-main .faucet-box .faucet-container .faucet-from .amount,
.faucet-main .faucet-box .faucet-container .faucet-to .amount {
  @apply block bg-transparent leading-none mb-0 border-0 outline-0 shadow-none;
  width: 100%;
  font-size: 22px;
  padding: 0 !important;
}
.faucet-main .faucet-box .faucet-container .input-box {
    @apply block bg-transparent leading-none mb-0 md:mb-1.5 border-0 outline-0 shadow-none;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
    box-sizing: border-box;
    padding: 14px 20px;
    background: rgba(244, 244, 245, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 16px;
  }
.faucet-main .faucet-box .faucet-container .faucet-from .amount:focus,
.faucet-main .faucet-box .faucet-container .faucet-to .amount:focus {
  outline: 0;
  box-shadow: none;
}
.faucet-main .faucet-box .faucet-container .faucet-from .amount::placeholder,
.faucet-main .faucet-box .faucet-container .faucet-to .amount::placeholder {
  color: var(--text-dark-500);
}
.faucet-main
  .faucet-box
  .faucet-container
  .faucet-from
  .amount::-webkit-input-placeholder,
.faucet-main
  .faucet-box
  .faucet-container
  .faucet-to
  .amount::-webkit-input-placeholder {
  color: var(--text-dark-500);
}
.faucet-main
  .faucet-box
  .faucet-container
  .faucet-from
  .amount::-moz-placeholder,
.faucet-main
  .faucet-box
  .faucet-container
  .faucet-to
  .amount::-moz-placeholder {
  color: var(--text-dark-500);
}
.faucet-main
  .faucet-box
  .faucet-container
  .faucet-from
  .amount:-ms-input-placeholder,
.faucet-main
  .faucet-box
  .faucet-container
  .faucet-to
  .amount:-ms-input-placeholder {
  color: var(--text-dark-500);
}
.faucet-main .faucet-box .faucet-container .faucet-from .balance,
.faucet-main .faucet-box .faucet-container .faucet-to .balance {
  @apply text-base leading-none mb-0;
  font-weight: var(--fw-400);
  color: var(--text-dark-500);
}
.faucet-main .faucet-box .input-title {
  color: #717179;
}
