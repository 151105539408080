.farm-details{
    @apply max-w-xl mx-auto py-5;
}
.farm-details .go-back-link{
    @apply flex items-center text-base mb-4 no-underline;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.farm-details .icons{
    @apply flex items-center;
}
.farm-details .icons img{
    @apply relative inline rounded-full;
    width: 44px;
    min-width: 45px;
}
.farm-details .icons img:nth-of-type(2){
    left: -15px;
}
.farm-details .coins{
    @apply flex items-center text-xl md:text-2xl leading-none md:leading-none mb-1;
    font-weight: var(--fw-600);
    color: var(--text-white-400);
}
.farm-details .fee{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}