/* Start IDO Tabs */
.ido-main{
    @apply min-h-full py-5;
}
.ido-main .btn-apply-ido{
    @apply h-11 text-sm inline-flex items-center justify-center mt-5 md:mt-8 mb-5 md:mb-0;
    width: 300px;
    font-weight: var(--fw-700);
    color: var(--text-warning-300);
    background: var(--bg-warning-200);
    border: 1px solid rgba(255, 223, 186, 0.05);
    border-radius: 44px;
}
.ido-main .ido-tabs{
    @apply flex items-center gap-x-8 pt-2.5 pb-9;
}
.ido-main .ido-tabs .tab-item{
    @apply flex items-center text-sm m-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.ido-main .ido-tabs .tab-item.active{
    color: var(--text-white-200);
}
.ido-main .ido-tabs .tab-item .count{
    @apply flex items-center justify-center w-5 h-5 text-xs rounded-full ml-2.5;
    font-weight: var(--fw-400);
    color: var(--text-warning-300);
    background-color: var(--bg-warning-300);
}
/* End IDO Tabs */

/* Start IDO Single Card */
.single-ido-card{
    @apply rounded-b-md;
    background-color: var(--bg-dark-300);
    border: 1px solid rgb(255 255 255 / 7%);
    backdrop-filter: blur(2px);
}
.single-ido-card .header{
    @apply overflow-hidden;
}
.single-ido-card .body{
    @apply relative px-4 pt-6 pb-4;
}
.single-ido-card .body .name{
    @apply flex items-center mb-7;
}
.single-ido-card .body .badge-live{
    @apply absolute top-6 right-4 w-8 h-6 flex items-center justify-center rounded-lg;
    font-size: 10px;
    font-weight: var(--fw-500);
    color: var(--text-white-800);
    background-color: var(--bg-dark-1500);
}
.single-ido-card .body .lg-text{
    @apply text-sm leading-none mb-1.5;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.single-ido-card .body .sm-text{
    @apply text-xs leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
.single-ido-card .body .item .key{
    @apply text-xs leading-none mb-3;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.single-ido-card .body .item .value{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
/* End IDO Single Card */


.ido-details{
    @apply py-5;
}
.ido-details .go-back-link{
    @apply inline-flex items-center text-base mb-6 md:mb-10 no-underline;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}

/* IDO Details Tabs */
.ido-details .ido-details-tabs{
    @apply flex items-center gap-x-8 pl-4 mb-9;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.ido-details .ido-details-tabs .tab-item{
    @apply text-sm py-3 border-b border-transparent;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
    transition: all 0.3s;
}
.ido-details .ido-details-tabs .tab-item.active{
    color: var(--text-warning-300);
    border-color: var(--text-warning-300);
}
/* IDO Details Tabs */

/* Tab - Token Sale */
.token-sale-list{
    @apply rounded-xl overflow-hidden;
    background: var(--bg-dark-1400);
    border: 1px solid rgba(255, 255, 255, 0.08);
}
.token-sale-list .header{
    @apply text-base rounded-t-xl p-4;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
    background-color: var(--bg-danger-300);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.token-sale-list .body .item{
    @apply text-base flex justify-between p-4;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.token-sale-list .body .item:last-of-type{
    @apply rounded-b-xl;
    border-bottom: 0;
}
/* Tab - Token Sale */

/* IDO Details - Left Side */
.ido-details hr{
    @apply w-full h-0.5;
    background-color: rgba(255, 255, 255, 0.08);
}
.ido-details .left-side .ido-details-heading-sm{
    @apply text-xs md:text-sm leading-none uppercase mb-2.5;
    font-weight: var(--fw-600);
    color: var(--text-warning-300);
}
.ido-details .left-side .ido-details-heading-lg{
    @apply text-xl md:text-2xl leading-tight mt-0 mb-2.5;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.ido-details .left-side .ido-general-text{
    @apply text-sm mb-3;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
/* IDO Details - Left Side */

/* IDO Details - Right Side */
.ido-details .right-side .defy-card {
    @apply py-6 rounded-md;
    padding-left: 18px;
    padding-right: 18px;
    background-color: var(--bg-dark-1400);
    backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.07);
}
.defy-card .header{
    @apply flex items-center;
}
.defy-card .header .heading{
    @apply text-2xl mt-0 mb-0.5;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.defy-card .header .desc{
    @apply text-sm mb-0;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}
.defy-card hr{
    @apply w-full h-0.5;
    background-color: rgba(255, 255, 255, 0.06);
}
.defy-card .total-raised .heading{
    @apply text-sm leading-none mt-0 mb-1;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}
.defy-card .total-raised .amount{
    @apply flex items-end text-2xl mt-0 mb-0.5;
    font-weight: var(--fw-700);
    color: var(--text-white-200);
}
.defy-card .total-raised .amount span{
    @apply text-lg ml-0.5;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.defy-card .key{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}
.defy-card .value{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-white-200);
}
.defy-card .value.text-warning{
    color: var(--text-warning-300) !important;
}

.ido-details .right-side .low-balance{
    @apply relative flex items-center justify-between px-3 py-4 mt-4;
    background-color: var(--bg-dark-300);
    border-radius: 10px;
}
/* .ido-details .right-side .low-balance .lg-text{
    @apply text-xl leading-none mb-0;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
} */
.ido-details .right-side .low-balance .field{
    @apply block bg-transparent text-xl leading-none p-0 mb-0 md:mb-1.5 border-0 outline-0 shadow-none;
    width: 70%;
    font-weight: var(--fw-500);
    color: var(--text-white-200);
}
.ido-details .right-side .low-balance .field:focus{
    outline: 0 ;
    box-shadow: none ;
}
.ido-details .right-side .low-balance .field::placeholder{
    color: var(--text-dark-500);
}
.ido-details .right-side .low-balance .field::-webkit-input-placeholder {
    color: var(--text-dark-500);
}
.ido-details .right-side .low-balance .field:-moz-placeholder {
    color: var(--text-dark-500);
}
.ido-details .right-side .low-balance .field:-ms-input-placeholder {
    color: var(--text-dark-500);
}
.ido-details .right-side .low-balance .sm-text{
    @apply absolute text-xs leading-none mb-0;
    right: 12px;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}

.ido-details .right-side .defy-content .heading{
    @apply text-xl leading-none mb-2.5;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.ido-details .right-side .defy-content .desc{
    @apply text-sm leading-snug mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}

.ido-details .right-side .selected-item{
    @apply block xl:flex items-center justify-between py-4 rounded-md;
    padding-left: 18px;
    padding-right: 18px;
    background-color: var(--bg-dark-300);
    backdrop-filter: blur(2px);
}
.ido-details .right-side .selected-item .name{
    @apply text-sm leading-none mb-1.5;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}
.ido-details .right-side .selected-item .amount{
    @apply flex items-end text-base mt-0 mb-0.5;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.ido-details .right-side .selected-item .amount span{
    @apply text-sm ml-0.5;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}

.ido-details .right-side .selected-item .btn-remove{
    @apply w-full xl:w-auto text-xs p-2.5 rounded-lg;
    font-weight: var(--fw-700);
    color: var(--text-danger-400);
    background-color: var(--bg-danger-400);
}
/* IDO Details - Right Side */