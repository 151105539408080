.right-sidebar.desktop {
    position: fixed;
    right: -375px;
    top: 8px;
    bottom: 8px;
    height: calc(100vh - 16px);
    width: 375px;
    box-sizing: border-box;
    visibility: hidden;
    background: rgba(71, 71, 71, 0.05);
    backdrop-filter: blur(32px);
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 10px;
    transition: all .3s ease;
    z-index: 10;
}
.right-sidebar.desktop.show {
    right: 8px;
    visibility: visible;
}
.right-sidebar.desktop .close-right-sidebar{
    @apply w-5 h-5 absolute opacity-0 invisible;
    top: 16px;
    left: -27px;
    transition: all 0.3s;
}
.right-sidebar.desktop.show .close-right-sidebar{
    @apply opacity-100 visible;
    transition: all 0.3s;
}

/* Start Right Sidebar Tabs */
.right-sidebar .wallet-info{
    padding: 25px 20px 20px 20px;
}
.right-sidebar .wallet-info .profile{
    @apply flex items-center;
}
.right-sidebar .wallet-info .profile .icon{
    @apply w-10 h-10 mr-2.5;
}
.right-sidebar .wallet-info .profile .name{
    @apply text-lg leading-none mb-1.5;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.right-sidebar .wallet-info .profile .text{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}
.right-sidebar .wallet-info .action-btns{
    @apply flex items-center;
    column-gap: 12px;
}
.right-sidebar .wallet-info .action-btns button{
    @apply h-8 w-8 flex items-center justify-center rounded-lg;
    background-color: var(--bg-dark-1200);
}

.right-sidebar .wallet-info .balance .heading{
    @apply text-xs leading-none mb-1;
    font-weight: var(--fw-400);
    color: var(--text-dark-400);
}
.right-sidebar .wallet-info .balance .coin-name{
    @apply text-3xl text-white leading-none mb-0;
    font-weight: var(--fw-500);
}
.right-sidebar .wallet-info .balance .converted-into{
    @apply text-sm leading-none mb-1 ml-1;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}

.sidebar-tabs{
    @apply flex justify-between p-1.5 rounded-lg;
    background: var(--bg-dark-1100);
    border: 1px solid rgba(255, 255, 255, 0.05);
}
.sidebar-tabs .tab{
    @apply px-4 py-3 rounded-lg text-sm leading-none;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
    background: transparent;
    border: 1px solid transparent;
    transition: all 0.2s;
}
.sidebar-tabs .tab.active{
    color: var(--text-white-100);
    background: var(--bg-dark-300);
    border: 1px solid rgba(0, 0, 0, 0.07);
}
.sidebar-tab-content{
    max-height: calc(100vh - 257px);
    overflow-y: auto;
    padding-right: 10px;
}
.sidebar-tab-content::-webkit-scrollbar-track{
	background-color: #08090A;
}
.sidebar-tab-content::-webkit-scrollbar{
	width: 5px;
}
.sidebar-tab-content::-webkit-scrollbar-thumb{
	background-color: #717179;
    border-radius: 2px;
}
.sidebar-tab-content .item{
    @apply flex justify-between;
    column-gap: 25px;
    padding: 18px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07); 
}
.sidebar-tab-content .item .left{
    @apply flex items-center;
}
.sidebar-tab-content .item .left .icon{
    @apply overflow-hidden mr-3 overflow-hidden;
    border-radius: 50%;
}
.sidebar-tab-content .item .left .text-upr{
    @apply text-base leading-none mb-1;
    font-weight: var(--fw-400);
    color: var(--text-white-200);
}
.sidebar-tab-content .item .left .text-lwr{
    @apply text-sm leading-none whitespace-nowrap mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
.sidebar-tab-content .item .right .text-upr{
    @apply text-base text-right leading-none mb-1;
    font-weight: var(--fw-400);
    color: var(--text-white-200);
}
.sidebar-tab-content .item .right .text-lwr{
    @apply text-base text-right leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
.sidebar-tab-content .item .right .progress{
    @apply flex items-center justify-end leading-none;
    column-gap: 6px;
}
.sidebar-tab-content .item .right .progress .text{
    @apply text-sm text-right leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}

.sidebar-tab-content.pool-content .item .left .icon{
    @apply flex items-center justify-center;
    background-color: var(--bg-warning-300);
}
.sidebar-tab-content.activity-content .item.sent .left .icon{
    @apply flex items-center justify-center;
    background-color: var(--bg-warning-300);
}
.sidebar-tab-content.activity-content .item.recieved .left .icon{
    @apply flex items-center justify-center;
    background-color: var(--bg-success-100);
}
/* End Right Sidebar Tabs */

/* Start Right Sidebar Connect Wallet */
.right-sidebar .connect-wallet{
    padding: 20px 8px;
}
.right-sidebar .connect-wallet .heading{
    @apply text-lg leading-none mb-1.5 pl-4;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.right-sidebar .connect-wallet .action-btns{
    @apply flex items-center;
    column-gap: 12px;
}
.right-sidebar .connect-wallet .action-btns button{
    @apply h-8 w-8 flex items-center justify-center rounded-lg;
    background-color: var(--bg-dark-1200);
}
.right-sidebar .connect-wallet .wallets-list{
    @apply overflow-auto mb-2.5;
}
.right-sidebar .connect-wallet .wallets-list .item{
    @apply flex justify-between p-3.5 mb-1.5 rounded-lg;
    column-gap: 25px;
    background-color: transparent;
}
.right-sidebar .connect-wallet .wallets-list .item.active{
    @apply flex justify-between p-3.5;
    background-color: var(--bg-dark-1200);
}
.right-sidebar .connect-wallet .wallets-list .item .icon{
    @apply w-9 h-9 overflow-hidden mr-3 overflow-hidden rounded-lg overflow-hidden mr-3;
}
.right-sidebar .connect-wallet .wallets-list .item .name{
    @apply text-base leading-none capitalize mb-0;
    font-weight: var(--fw-700);
    color: var(--text-white-200);
    letter-spacing: -0.2px;
}
.right-sidebar .connect-wallet .wallets-list .item .not-installed{
    @apply text-xs italic leading-none mb-0;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
    letter-spacing: -0.2px;
}
.right-sidebar .connect-wallet .t-and-c-text{
    @apply text-xs leading-4 mb-0;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}
.right-sidebar .connect-wallet .t-and-c-text a{
    @apply inline no-underline;
    color: var(--text-warning-300);
}
/* End Right Sidebar Connect Wallet */
.rangeslider__labels .rangeslider__label-item {
    position: relative;
    font-size: 13px !important;
    top: 8px !important;
    color: #717179;
}

.range-percentage {
    padding-right: 14px !important;
    font-size: 13px !important;
    max-width: 48px;
    padding: 3px;
    border-radius: 4px;
    color: rgba(11, 12, 12, 1);
    background-color: rgba(11, 12, 12, 1);
    border: 1px solid rgba(255, 255, 255, 0.08)
}

.range-percentage:focus {
    border: 1px solid rgba(255, 255, 255, 0.08);
    outline: 1px solid rgba(255, 255, 255, 0.08);
}

.rangeslider__label-item::before {
    position: absolute;
    top: -14.5px;
    left: 5px;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.08);;
    font-size: 14px;
    content: '';
}

.rangeslider__label-item:nth-last-child(1)::before {
    left: -4px;
}

.rangeslider__label-item:nth-last-child(5)::before {
    left: 13.5px;
}
