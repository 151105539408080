.main-nav{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 9;
    box-shadow: 0px 4px 30px 0px rgba(46, 45, 116, 0.05);
}

/* Start Notification Dropdown */
.notification-dropdown{
    @apply w-full px-5 py-6;
    max-width: 375px;
    background-color: rgba(71, 71, 71, 0.05);
    backdrop-filter: blur(32px);
    border-radius: 10px;
    border: 1px solid rgb(255 255 255 / 7%);
    position: absolute;
    top: 65px;
    right: 20%;
}
.notification-dropdown .heading{
    @apply text-base md:text-xl text-white leading-none mb-3;
    font-weight: var(--fw-600);
}
.notification-dropdown .list{
    margin-bottom: 18px;
}
.notification-dropdown .list .item{
    @apply flex items-start;
    padding: 18px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}
.notification-dropdown .list .item .title{
    @apply text-sm md:text-base leading-none mb-1;
    font-weight: var(--fw-400);
    color: var(--text-white-200);
    transition: all 0.3s;
}
.notification-dropdown .list .item:hover .title{
    color: #fff;
    transition: all 0.3s;
}
.notification-dropdown .list .item .desc{
    @apply text-xs leading-normal mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
.notification-dropdown .list .item:hover .desc{
    color: #fff;
    transition: all 0.3s;
}
.notification-dropdown .btn-see-all{
    @apply block w-full h-10 text-sm text-center py-2 px-4 rounded-full;
    font-weight: var(--fw-500);
    color: var(--text-warning-300);
    background: rgba(255, 223, 186, 0.09);
    border: 1px solid rgba(255, 223, 186, 0.05);
}
/* End Notification Dropdown */

/* Start Notification Page */
.notifications-main{
    @apply py-5; 
}


.notifications-main .sm-notifications-filter{
    @apply flex md:hidden items-center justify-between mt-9 mb-6;
}
.sm-notifications-filter .heading{
    @apply text-sm leading-none;
    font-weight: var(--fw-600);
    color: rgba(255, 255, 255, 0.24);
}
.sm-notifications-filter .btn-select{
    @apply flex items-center gap-x-2.5 px-3 py-2 text-sm text-white rounded-full;
    font-weight: var(--fw-600);
    background-color: var(--bg-dark-1400);
}
.sm-notifications-filter .dd-list{
    @apply w-full z-10 p-2 rounded-lg;
    background-color: #0D0807;
    border: 1px solid rgba(255, 255, 255, 0.04);
}
.sm-notifications-filter .dd-list li{
    @apply mb-1.5;
}
.sm-notifications-filter .dd-list li:last-child{
    @apply mb-0;
}
.sm-notifications-filter .dd-list li .item{
    @apply flex items-center justify-between;
}
.sm-notifications-filter .dd-list li .item .text{
    @apply text-xs ml-2;
    font-weight: var(--fw-500);
    color: #D9D2D2;
}


.all-notifications{
    @apply w-full px-5 py-6 rounded-md;
    background-color: var(--bg-dark-500);
    backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.04);
}
.all-notifications .item{
    @apply flex items-start;
    padding: 18px 0;
    cursor: default !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}
.all-notifications .item:first-child{
    padding-top: 0;
}
.all-notifications .item .title{
    @apply text-sm md:text-base leading-tight mb-1;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
    transition: all 0.3s;
}
.all-notifications .item:hover .title{
    color: #fff;
    transition: all 0.3s;
}
.all-notifications .item .desc{
    @apply text-xs md:text-sm leading-normal mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
.all-notifications .item:hover .desc{
    color: #fff;
    transition: all 0.3s;
}

/* End Notification Page */
@media screen and (max-width: 500.98px) {
    .notification-dropdown  {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0%);
    }
}