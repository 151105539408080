.swap-main{
    @apply flex justify-center min-h-full;
    padding: 20px 0;
}
.swap-main .swap-box{
    @apply rounded-lg px-3.5 md:px-6 py-4 md:py-7;
    width: 100%;
    max-width: 580px;
    background: var(--bg-dark-600);
    border: 1px solid rgb(255 255 255 / 7%);
}
.swap-main .swap-box .header{
    @apply flex items-center justify-between mb-4 md:mb-6;
}
.swap-main .swap-box .header .heading{
    @apply leading-none;
    font-size: 24px;
    font-weight: var(--fw-400);
    color: var(--text-white-100);
}

.swap-main .swap-box .header .btn-settings{
    @apply px-1 py-1;
}
.swap-main .swap-box .header .btn-settings img{
    @apply w-4 md:w-6;
}

.swap-main .swap-box .swap-container{
    @apply relative mb-2.5 md:mb-5;
}
.swap-main .swap-box .swap-container .swap-from,
.swap-main .swap-box .swap-container .swap-to{
    @apply rounded-2xl pt-2 md:pt-3 px-2.5 md:px-4 pb-4;
    background: var(--bg-dark-500);
    border: 1px solid rgba(255, 255, 255, 0.02);
}
.swap-main .swap-box .swap-container .swap-from .sm-text,
.swap-main .swap-box .swap-container .swap-to .sm-text{
    @apply text-xs leading-none mb-0;
    font-weight: var(--fw-700);
    color: var(--text-dark-500);
}
.swap-main .swap-box .swap-container .swap-from .badge{
    @apply rounded-lg text-xs w-8 md:w-11 h-4 md:h-6 flex items-center justify-center mb-0;
    font-weight: var(--fw-700);
    color: var(--text-dark-300);
    background: var(--bg-dark-800);
}
.swap-main .swap-box .swap-container .swap-from .amount,
.swap-main .swap-box .swap-container .swap-to .amount{
    @apply block bg-transparent leading-none p-0 mb-0 md:mb-1.5 border-0 outline-0 shadow-none;
    width: 95%;
    font-size: var(--text-3xl-34);
    margin-bottom: 0.505rem;;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.swap-main .swap-box .swap-container .swap-from .amount:focus,
.swap-main .swap-box .swap-container .swap-to .amount:focus{
    outline: 0 ;
    box-shadow: none ;
}
.swap-main .swap-box .swap-container .swap-from .amount::placeholder,
.swap-main .swap-box .swap-container .swap-to .amount::placeholder{
    color: var(--text-dark-500);
}
.swap-main .swap-box .swap-container .swap-from .amount::-webkit-input-placeholder,
.swap-main .swap-box .swap-container .swap-to .amount::-webkit-input-placeholder {
    color: var(--text-dark-500);
}
.swap-main .swap-box .swap-container .swap-from .amount::-moz-placeholder,
.swap-main .swap-box .swap-container .swap-to .amount::-moz-placeholder {
    color: var(--text-dark-500);
}
.swap-main .swap-box .swap-container .swap-from .amount:-ms-input-placeholder,
.swap-main .swap-box .swap-container .swap-to .amount:-ms-input-placeholder {
    color: var(--text-dark-500);
}
.swap-main .swap-box .swap-container .swap-from .balance,
.swap-main .swap-box .swap-container .swap-to .balance{
    @apply text-base leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
.swap-main .swap-box .swap-container .swap-from .btn-select-coin,
.swap-main .swap-box .swap-container .swap-to .btn-select-coin{
    background: rgba(255, 255, 255, 0.06);
    border-radius: 90px;
    padding: 6px 10px 6px 8px;
    margin-bottom: 12px;
    @apply relative flex items-center py-1.5 pl-1.5 leading-none shadow-none outline-0;
}
.swap-main .swap-box .swap-container .swap-from .btn-select-coin .icon,
.swap-main .swap-box .swap-container .swap-to .btn-select-coin .icon{
    border-radius: 100%;
    border: 1px solid #222;
    border-radius: 100%;
    @apply mr-1.5 w-5 md:w-8;
}
.swap-main .swap-box .swap-container .swap-from .btn-select-coin .currency,
.swap-main .swap-box .swap-container .swap-to .btn-select-coin .currency{
    @apply text-sm md:text-base text-white whitespace-nowrap mb-0;
    font-weight: var(--fw-400);
}
.swap-main .swap-box .swap-container .btn-swap{
    @apply absolute left-1/2 flex items-center justify-center rounded-md md:rounded-lg shadow-none outline-0;
    top: calc(50% + 3px);
    transform: translate(-50%, -50%);
    height: 40px;
    width: 56px;
    border: 1px solid rgba(255, 255, 255, 0.03);
    border-radius: 48px;
    background-color: rgba(16, 13, 13, 1);
}
.swap-main .swap-box .swap-container .btn-swap .icon{
    @apply w-2.5 md:w-5;
}
.swap-main .swap-box .eth-per-ampl{
    @apply flex items-center justify-between px-1.5 mb-6;
}
.swap-main .swap-box .eth-per-ampl .sm-text{
    @apply text-sm md:text-base leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
.swap-main .swap-box .eth-per-ampl .lg-text{
    @apply text-sm md:text-lg leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.swap-main .swap-box .eth-per-ampl .btn-reload{
    @apply py-1 px-1.5 ml-2;
}
.swap-main .swap-box .eth-per-ampl .btn-reload .icon{
    @apply w-3 md:w-4;
}
.swap-main .swap-box hr{
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.swap-main .swap-box .swap-details .key{
    @apply flex items-center gap-x-2;
}
.swap-main .swap-box .swap-details .key .text{
    @apply text-sm md:text-base capitalize leading-none md:leading-none mb-0;
    font-weight: var(--fw-400);
    color: rgba(113, 113, 121, 1);
}
.swap-main .swap-box .swap-details .key .icon{
    width: 18px;
    border-radius: 100%;
    border: 1px solid #222;
}
.swap-main .swap-box .swap-details .value{
    @apply text-sm md:text-lg text-right leading-none md:leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}

/* Token Selection Modal */
#tokenSelectionModal{
    backdrop-filter: blur(8px);
}
#tokenSelectionModal .modal-content{
    @apply relative w-full rounded-lg;
    max-width: 435px;
    background-color: var(--bg-dark-700);
    border: 1px solid rgba(255, 255, 255, 0.06);
}
#tokenSelectionModal .modal-content .modal-header{
    @apply flex items-center justify-between px-3 md:px-4 pt-5 mb-4 md:mb-5;
}
#tokenSelectionModal .modal-content .modal-header .modal-title{
    @apply text-xl leading-none;
    font-weight: var(--fw-700);
    color: var(--text-white-300);
}
#tokenSelectionModal .modal-content .modal-header .btn-close{
    @apply p-1.5 ml-auto inline-flex items-center justify-center;
    color: var(--text-white-300);
}
#tokenSelectionModal .modal-content .search-token{
    @apply w-full px-4 py-3 rounded-lg text-sm outline-0 focus:shadow-none;
    font-weight: var(--fw-500);
    color: var(--text-white-200);
    background: var(--bg-dark-200);
    border: 1px solid rgba(221, 223, 228, 0.07);
}
#tokenSelectionModal .modal-content .search-token:focus{
    box-shadow: none;
    outline: 0;
    border-color: rgba(221, 223, 228, 0.07);
}
#tokenSelectionModal .modal-content .popular-tokens{
    @apply px-3 md:px-4 pb-4;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
#tokenSelectionModal .modal-content .popular-tokens .heading{
    @apply text-xs md:text-sm leading-none mb-4;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
#tokenSelectionModal .modal-content .popular-tokens .tokens{
    @apply flex gap-2 md:gap-4 flex-wrap overflow-y-auto;
    max-height: 100px;
}
#tokenSelectionModal .modal-content .popular-tokens .tokens::-webkit-scrollbar-track{
	background-color: #08090A;
}
#tokenSelectionModal .modal-content .popular-tokens .tokens::-webkit-scrollbar{
	width: 5px;
}
#tokenSelectionModal .modal-content .popular-tokens .tokens::-webkit-scrollbar-thumb{
    border-radius: 3px;
	background-color: #717179;
}
#tokenSelectionModal .modal-content .popular-tokens .tokens .item{
    @apply flex items-center px-2.5 py-2 md:py-2.5 text-xs;
    font-weight: var(--fw-600);
    color: var(--text-white-500);
    white-space: nowrap;
    background-color: var(--bg-dark-300);
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 30px;
}
#tokenSelectionModal .modal-content .popular-tokens .tokens .item .icon{
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 10px;
    border-radius: 100%;
    border: 1px solid #222;
}
#tokenSelectionModal .modal-content .searched-coins{
    max-height: 325px;
}
#tokenSelectionModal .modal-content .searched-coins::-webkit-scrollbar-track {
    background-color: #08090A;
    border-radius: 0;
}
#tokenSelectionModal .modal-content .searched-coins::-webkit-scrollbar {
    width: 5px;
}
#tokenSelectionModal .modal-content .searched-coins::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #717179;
}
#tokenSelectionModal .modal-content .searched-coins .item{
    @apply flex items-center justify-between px-3 md:px-4 py-3 md:py-5 rounded-sm cursor-pointer;
    border-bottom: 1px solid rgba(237, 233, 233, 0.08);
}
#tokenSelectionModal .modal-content .searched-coins .item:hover{
    background-color: var(--bg-dark-300);
}
#tokenSelectionModal .modal-content .searched-coins .item .coin-name{
    @apply flex items-center ;
}
#tokenSelectionModal .modal-content .searched-coins .item .coin-name .icon{
    @apply mr-3 ;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid #222
}
#tokenSelectionModal .modal-content .searched-coins .item .coin-name .lg-text{
    @apply text-base md:text-lg leading-none md:leading-none mb-1.5 md:mb-2;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
#tokenSelectionModal .modal-content .searched-coins .item .coin-name .sm-text{
    @apply text-xs md:text-sm leading-none md:leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
#tokenSelectionModal .modal-content .searched-coins .item .coin-value{
    @apply text-base md:text-lg leading-none md:leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}


/* Transaction Settings Modal */
#transactionSettingsModal{
    backdrop-filter: blur(8px);
}
#transactionSettingsModal .modal-content{
    @apply relative w-full rounded-lg px-5 pt-6 pb-6;
    max-width: 450px;
    background-color: var(--bg-dark-700);
    border: 1px solid rgba(255, 255, 255, 0.06);
}
#transactionSettingsModal .modal-content .modal-header{
    @apply flex items-center justify-between mb-5;
}
#transactionSettingsModal .modal-content .modal-header .modal-title{
    @apply text-lg md:text-xl text-white leading-none;
    font-weight: var(--fw-700);
}
#transactionSettingsModal .modal-content .modal-header .btn-close{
    @apply p-1.5 ml-auto inline-flex items-center justify-center rounded-3xl;
    color: var(--text-white-300);
    background-color: var(--bg-dark-100);
}
#transactionSettingsModal .modal-content .slippage-tolerance{
    @apply flex items-center justify-between mb-4;
}
#transactionSettingsModal .modal-content .slippage-tolerance .text{
    @apply flex items-center text-base leading-none;
    font-weight: var(--fw-500);
    color: var(--text-warning-100);
}
#transactionSettingsModal .modal-content .slippage-tolerance .value{
    @apply text-sm md:text-base leading-none;
    font-weight: var(--fw-500);
    color: var(--text-warning-100);
}
#transactionSettingsModal .modal-content .tolerance-selection{
    @apply flex items-center justify-between w-full p-1.5 rounded-md mb-5;
    border: 1px solid rgba(221, 223, 228, 0.07);
}
#transactionSettingsModal .modal-content .tolerance-selection .item input{
    display: none;
}
#transactionSettingsModal .modal-content .tolerance-selection .item label{
    @apply flex items-center justify-center text-sm md:text-base rounded-lg;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
    width: 55px;
    height: 40px;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
}
#transactionSettingsModal .modal-content .tolerance-selection .item input:checked + label{
    font-weight: var(--fw-600);
    color: var(--text-white-400);
    background-color: var(--bg-dark-900);
    border: 1px solid rgba(0, 0, 0, 0.07);
}
#transactionSettingsModal .modal-content .custom-percentage{
    @apply flex items-center justify-between md:justify-start;
}
#transactionSettingsModal .modal-content .custom-percentage .text{
    @apply text-sm mb-0 leading-none;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}
#transactionSettingsModal .modal-content .custom-percentage .field{
    @apply text-sm ml-6 text-center rounded-md outline-0 shadow-none;
    width: 68px;
    height: 48px;
    font-weight: var(--fw-700);
    color: var(--text-dark-500);
    background-color: var(--bg-dark-1000);
    border: 1px solid rgba(255, 255, 255, 0.05);
}
#transactionSettingsModal .modal-content .custom-percentage .field:focus{
    box-shadow: none;
    outline: 0;
}

/* Confirm Swap Modal */
#confirmSwapModal{
    backdrop-filter: blur(8px);
}
#confirmSwapModal .modal-content{
    @apply relative w-full px-3.5 md:px-5 py-4 md:py-6 rounded-lg;
    max-width: 480px;
    background-color: var(--bg-dark-700);
    border: 1px solid rgba(255, 255, 255, 0.06);
}
#confirmSwapModal .modal-content .modal-header{
    @apply flex items-center justify-between mb-3.5 md:mb-4;
}
#confirmSwapModal .modal-content .modal-header .modal-title{
    @apply text-base md:text-lg leading-none capitalize;
    font-weight: var(--fw-700);
    color: var(--text-white-100);
}
#confirmSwapModal .modal-content .modal-header .btn-close{
    @apply p-1.5 ml-auto inline-flex items-center justify-center;
    color: var(--text-white-300);
}

#confirmSwapModal .modal-content .swap-container .swapable{
    @apply rounded-2xl px-2.5 pt-3.5 pb-4 md:p-4;
    background: var(--bg-dark-1300);
    border: 1px solid rgba(0, 0, 0, 0.07);
}
#confirmSwapModal .modal-content .swap-container .swapable .sm-text{
    @apply text-xs leading-none mb-0;
    font-weight: var(--fw-700);
    color: var(--text-dark-500);
}
#confirmSwapModal .modal-content .swap-container .swapable .amount{
    @apply text-2xl text-white leading-none mb-0;
    font-weight: var(--fw-600);
}
#confirmSwapModal .modal-content .swap-container .swapable .balance{
    @apply text-sm md:text-base leading-none mb-0;
    font-weight: var(--fw-400);
    color: var(--text-dark-500);
}
#confirmSwapModal .modal-content .swap-container .swapable .selected-coin{
    @apply flex items-center;
}
#confirmSwapModal .modal-content .swap-container .swapable .selected-coin .icon{
    border-radius: 100%;
    border: 1px solid #222;
    @apply mr-1.5 md:mr-2.5 w-5 md:w-6;
}
#confirmSwapModal .modal-content .swap-container .swapable .selected-coin .currency{
    @apply text-sm md:text-base mb-0;
    font-weight: var(--fw-600);
    color: var(--text-white-600);
}
#confirmSwapModal .modal-content .swap-container .btn-swap{
    @apply absolute left-1/2 flex items-center justify-center rounded-md md:rounded-lg shadow-none outline-0;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 56px;
    border: 1px solid rgba(255, 255, 255, 0.03);
    border-radius: 48px;
    background-color: rgba(15, 16, 17, 1);
    cursor: default;
}
#confirmSwapModal .modal-content .swap-container .btn-swap .icon{
    @apply w-2.5 md:w-4;
}

#confirmSwapModal .modal-content .collapsible-swap-details{
    @apply w-full p-3;
    background-color: rgba(244, 244, 245, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 10px;
}
#confirmSwapModal .modal-content .collapsible-swap-details button{
    @apply flex items-center justify-between gap-x-5 text-sm md:text-base;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
#confirmSwapModal .modal-content .collapsible-swap-details #collapsible-details{
    @apply pt-4;
}
#confirmSwapModal .modal-content .collapsible-swap-details #collapsible-details .key{
    @apply text-sm capitalize leading-none mb-0;
    font-weight: var(--fw-400);
}
#confirmSwapModal .modal-content .collapsible-swap-details #collapsible-details .value{
    @apply text-sm text-right leading-none mb-0;
    font-weight: var(--fw-400);
}
#confirmSwapModal .modal-content .collapsible-swap-details #collapsible-details hr{
    border: 1px solid rgba(255, 255, 255, 0.1);
}


/* Swap Action Modals */
.swapActionModal{
    backdrop-filter: blur(8px);
}
.swapActionModal .modal-content{
    @apply relative w-full px-7 pb-6 rounded-lg;
    max-width: 485px;
    background-color: var(--bg-dark-700);
    border: 1px solid rgba(255, 255, 255, 0.06);
}
.swapActionModal .modal-content .heading{
    @apply text-xl md:text-2xl leading-tight md:leading-tight text-center mb-0;
    font-weight: var(--fw-600);
    color: var(--text-white-100);
}
.swapActionModal .modal-content .heading span{
    color: var(--text-warning-300);
}
.swapActionModal .modal-content .desc-lg{
    @apply text-lg md:text-xl text-center leading-snug md:leading-snug mb-0;
    font-weight: var(--fw-400);
    color: var(--text-white-200);
}
.swapActionModal .modal-content .desc-sm{
    @apply text-sm leading-tight text-center mb-0 mt-2;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.swapActionModal .modal-content .desc-sm a{
    text-decoration: none;
    color: var(--text-warning-300);
}
/* Swap Action Modals */

@media screen and (max-width: 767.98px) {
    .swap-main .swap-box .header .heading {
        font-size: 20px;
    }
    .swap-main .swap-box .swap-container .swap-from .amount,
    .swap-main .swap-box .swap-container .swap-to .amount{
        font-size: 24px;
    }
    .swap-main .swap-box .swap-container .btn-swap{
        height: 25px;
        width: 35px;
    }
    .swap-main .swap-box .swap-container .swap-from .badge{
        font-size: 10px;
    }
    #tokenSelectionModal .modal-content .popular-tokens .tokens {
        max-height: 80px;
    }
    #tokenSelectionModal .modal-content .popular-tokens .tokens .item .icon{
        border-radius: 100%;
        border: 1px solid #222;
        width: 15px;
        min-width: 15px;
        height: 15px;
        margin-right: 8px;
        border: 1px solid #eee;
    }
    #tokenSelectionModal .modal-content .searched-coins .item .coin-name .icon{
        border-radius: 100%;
        border: 1px solid #222;
        width: 26px;
        height: 26px;
        border: 1px solid #eee;
    }
    #confirmSwapModal .modal-content .swap-container .btn-swap{
        height: 25px;
        width: 35px;
    }
}