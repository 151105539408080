.modal-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-done {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 101px;
    height: 101px;
    border: 1px solid #ccc;
    border-radius: 100%;
    border-width: 5px;
    border-color: #E4761B;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 101px;
    height: 101px;
  }
  
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 101px;
    height: 101px;
    border: 5px solid #ccc;
    border-radius: 70%;
    animation: lds-ring calc(1.2s / var(--d,1)) cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #E4761B transparent transparent transparent;
  }
  
  .lds-ring div:nth-child(1) {
    animation-delay: calc(-0.45s / var(--d,1));
  }
  
  .lds-ring div:nth-child(2) {
    animation-delay: calc(-0.3s / var(--d,1));
  }
  
  .lds-ring div:nth-child(3) {
    animation-delay: calc(-0.15s / var(--d,1));
  }
  
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg)
    }
  }

  .wave {
    position:relative;
    text-align:center;
    margin-left: auto;
    margin-right: auto;
    .dot {
      display:inline-block;
      width:8px;
      height:8px;
      border-radius:50%;
      margin-right:3px;
      background:#303131;
      animation: wave 1.3s linear infinite;
  
      &:nth-child(2) {
        animation-delay: -1.1s;
      }
  
      &:nth-child(3) {
        animation-delay: -0.9s;
      }
    }
  }
  
  @keyframes wave {
    0%, 60%, 100% {
      transform: initial;
      background-color: #303131;
    }
  
    30% {
      background-color: #eee;
      transform: translateY(-8px);
    }
  }